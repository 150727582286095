.footer {
  bottom: 0;
  margin-top: 10rem;
  height: 2.5rem;
}

.socials a{
  color: grey;
  margin-right: 1.5rem;
  width: 3rem;
}