.nav {
  display: inline-flex;
  background-color: black;
  width: 100%;
  padding: 1.5rem;
  text-decoration: none;
  align-items: center;
  justify-content: center;
}

.navBar {
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.navBar a {
  display: inline-flex;
  color: goldenrod;
  text-decoration: none;
  background-color: black;
  font-size: medium;
  font-weight: 800;
  width: 100%;
  padding: 1rem;
  justify-content: center;
}

.dropdown button{
  display: inline-flex;
  border: none;
  color: goldenrod;
  background-color: black;
  font-size: medium;
  font-weight: 800;
  padding: 1rem;
  justify-content: center;
}

.dropdown-menu {
  display: none;
  background-color: #ddd;
  padding: 5px;
  border-radius: 5px;
  font-weight: 500;
}

.dropdown-menu a {
  text-decoration: none;
  background-color: #ddd;
  color: black;
}

.dropdown:hover .dropdown-menu {
  display: block;
  text-decoration: none;
}


