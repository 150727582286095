.contact input {
  width: 25rem;
  height: 2rem;
  font-size: large;
}

.contact button {
  background-color: black;
  color: goldenrod;
  padding: 1rem 2.5rem;
  font-size: large;
  font-weight: 800;
  border-radius: 10px;
}