.about {
  width: 80%;
  margin-left: 12.5rem;
  font-size: large;
}

.about ul {
  display: inline-flex;
  list-style: none;
}

.about li {
  margin-left: 5px;
}