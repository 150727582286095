.details {
  margin-top: 3.5rem;
  margin-left: 5rem;
  width: 90%;
}

.logo img{
  width: 50%;
}

.detailContainer p {
  font-size: larger;
}

.github {
  width: 5rem
}